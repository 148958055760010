/* EarlyAccessSection styles */

.storyliner-early-access-section {
  @apply flex flex-col justify-center my-0 mx-auto p-4 relative max-w-screen-md;
}

.storyliner-early-access-section-header {
  @apply flex flex-col justify-center w-full;
}

.storyliner-early-access-button {
  @apply flex justify-center mt-6 relative w-full;
}

.storyliner-early-access-scribble {
  @apply absolute hidden md:block h-44 w-44 z-50;

  @apply bg-scribble-scorpion-1 bg-center bg-no-repeat bg-contain;

  bottom: -75px;
  left: auto;
  right: 20px;
  transform: rotate(15deg);
}

.storyliner-early-access-section-helptext-container {
  @apply flex justify-center mt-5 w-full;
}
